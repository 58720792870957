import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';
import NumberList from './NumberList';
import CityLetterList from './CityLetterList';
import ProductList from './ProductList';
import ChannelList from './ChannelList';
import Carousel from 'react-bootstrap/Carousel'
import Url from 'url';
import FlexProductPanel from './FlexProductPanel'; 
import ProvinceCityData from './ProvinceCityData'

class HomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickCount: 0,numbers:[],hasNo4:true,
      has3A:false,
      has4A:false,
      hasAABB:false,
      hasABAB:false,
      hasABAC:false,
      hasABC:false,
      has8Limit:false,
      has4Limit:false,
      showCityText:true,
      totalCount:0,
      findCount:0,counter:0,
      showCity:false,
      showProduct:false,
      cityName:'深圳',
      cityCode: 560,
      scanRun:false,
      goodsId:'982203315714',
      productName:'联通王卡',
      productVCode:"ltwk",
      postCityCode:"440300",
      postProvinceCode:"440000",
      aabbList:[],
      ababList:[],
      abacList:[],
      abcList:[],
      showChannel:false,
      channelName:'渠道三',
      channelCode:'shenzhen',
      cacheChannelCode:'',
      u:'zuime'
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeHasNo4 = this.onChangeHasNo4.bind(this);
    this.onChangeHas3A = this.onChangeHas3A.bind(this);
    this.onChangeHas4A = this.onChangeHas4A.bind(this);
    this.onChangeHasAABB= this.onChangeHasAABB.bind(this);
    this.onChangeHasABAB= this.onChangeHasABAB.bind(this);
    this.onChangeHasABAC= this.onChangeHasABAC.bind(this);
    this.onChangeHasABC= this.onChangeHasABC.bind(this);
    this.onCityClick = this.onCityClick.bind(this);
    this.onProductClick = this.onProductClick.bind(this);
    this.onChooseCityClick = this.onChooseCityClick.bind(this);
    this.onChooseProductClick = this.onChooseProductClick.bind(this);
    this.onChooseChannelClick = this.onChooseChannelClick.bind(this);
    this.onStopClick = this.onStopClick.bind(this);
    this.job =null;
     
    //console.log(ProvinceCityData);
    if(window.location.hostname!=="localhost"){
      //hao.zhongdaiqi.com ->  hmb.zhongdaiqi.com
      if(window.location.hostname==="hao.zhongdaiqi.com"){
          //确保是https
          if(window.location.protocol==="http:"){
            console.log("自动跳转到https 确保用户数据安全");  
            window.location.href=window.location.href.replace("http://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }else{
            window.location.href=window.location.href.replace("https://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }
      }

      //确保是https
      if(window.location.protocol==="http:"){
        console.log("自动跳转到https 确保用户数据安全");  
        window.location.href=window.location.href.replace("http://","https://")
      }
    }
}

 // 页面加在完成
 componentDidMount(){

  console.log("智能追踪推广员");
  let finder = Url.parse(this.props.location.search,true); 
  var that = this;
  console.log(finder.query);
  if(finder.query.u){
    console.log("推荐员信息存在，重置默认推广员");
    this.setState({u:finder.query.u});
    //推广员默认城市
    if(finder.query.u==='Apathy'){
      this.setState({cityName:'沧州',cityCode:'317'});
    }
    try {
        //写入本地永久缓存
        localStorage.setItem("u",finder.query.u);//写入本地 追踪标
    }
    catch(err) {
      console.log("写入本地追踪标出现了异常");
    }
    
  }else{
    
    try {
      //尝试读取本地追踪标
      var local_u = localStorage.getItem("u");//获取存储的元素
      if(local_u){
        console.log("本地缓存有追踪标，重置默认推广员");
        that.setState({u:local_u});
      }
    }
    catch(err) {
      console.log("读取追踪标出现了异常");
    }
  }

  //默认城市
  console.log("修正默认归属地")
  axios.post( "/v2/api/ipBelong" )
    .then(function (response) {
        console.log("修正默认归属地")
        console.log(response)
 
        if(response.data.success){
          var  ltProCity = ProvinceCityData.find(x => x.numCityCode===response.data.data.cityCode);

          console.log(ltProCity);
          if(response.data.success){
            that.setState({
              cityName:response.data.data.cityName,
              cityCode: response.data.data.cityCode,
              postCityCode:ltProCity.postCityCode,
              postProvinceCode:ltProCity.postProvinceCode
            })
  
          }

        }
 });

  
}

filterKeyNumber = (nList,keyNumber,context)=>{
  console.log("keyNumber:"+keyNumber.value);
  if(keyNumber.value===''){
    return nList;
  }
  return nList.filter((item)=>{
    let serialNumber = item.serialNumber;
    if(context.has8Limit){
      serialNumber = item.serialNumber.substr(3,8);
    }else if(context.has4Limit){
      serialNumber = item.serialNumber.substr(7,4);
    } 
    console.log(item.serialNumber+"扫描位置"+serialNumber+" keyNumber.value:"+keyNumber.value)
    return serialNumber.indexOf(keyNumber.value)>-1
  })
}

filterNo4 = (nList,runFilter)=>{
  if(!runFilter){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  return nList.filter((item)=>{return item.serialNumber.indexOf(4)===-1})
}

filter3A = (nList,context)=>{
  if(!context.has3A){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  return nList.filter((item)=>{ 

    let serialNumber = item.serialNumber;
    if(context.has8Limit){
      serialNumber = item.serialNumber.substr(3,8);
    }else if(context.has4Limit){
      serialNumber = item.serialNumber.substr(7,4);
    } 


      return  serialNumber.indexOf('111')>-1
      ||serialNumber.indexOf('222')>-1
      ||serialNumber.indexOf('333')>-1
      ||serialNumber.indexOf('444')>-1
      ||serialNumber.indexOf('555')>-1
      ||serialNumber.indexOf('666')>-1
      ||serialNumber.indexOf('777')>-1
      ||serialNumber.indexOf('888')>-1
      ||serialNumber.indexOf('999')>-1
      ||serialNumber.indexOf('000')>-1}
  )
}

filter4A = (nList,context)=>{
  if(!context.has4A){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  return nList.filter((item)=>{ 
    let serialNumber = item.serialNumber;
    if(context.has8Limit){
      serialNumber = item.serialNumber.substr(3,8);
    }else if(context.has4Limit){
      serialNumber = item.serialNumber.substr(7,4);
    } 
    return serialNumber.indexOf('1111')>-1
      ||serialNumber.indexOf('2222')>-1
      ||serialNumber.indexOf('3333')>-1
      ||serialNumber.indexOf('4444')>-1
      ||serialNumber.indexOf('5555')>-1
      ||serialNumber.indexOf('6666')>-1
      ||serialNumber.indexOf('7777')>-1
      ||serialNumber.indexOf('8888')>-1
      ||serialNumber.indexOf('9999')>-1
      ||serialNumber.indexOf('0000')>-1}
  )
}

filterAABB = (nList,context)=>{
  if(!context.hasAABB){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  
  var checkAABBList = this.getAABBList();
  console.log("AABBList begin");
  console.log(checkAABBList)
  console.log("AABBList end");
  return nList.filter((item)=>{ 
    return checkAABBList.filter((xAABB)=>{
      if(context.has8Limit){
        return item.serialNumber.substr(3,8).indexOf(xAABB) >-1
      }else if(context.has4Limit){
        return item.serialNumber.substr(7,4).indexOf(xAABB) >-1
      }else{
        return item.serialNumber.indexOf(xAABB) >-1
      }

    }).length>0;
       
   })
}

getAABBList = ()=>{
  if(this.state.aabbList.length!==0){
    return this.state.aabbList;
  }
  var aabbList = [];
  for(var i=0;i<9;i++){
    for(var j=0;j<9;j++){
      aabbList.push(i+''+i+''+j+''+j);
    }
  }
  this.setState({
    aabbList:aabbList

  });
  return aabbList;
}

filterABAB = (nList,context)=>{
  console.log("hmb");
  if(!context.hasABAB){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  
  var checkABABList = this.getABABList();
  console.log("checkABABList begin");
  console.log(checkABABList)
  console.log("checkABABList end");
  return nList.filter((item)=>{ 
     return checkABABList.filter((xABAB)=>{
      if(context.has8Limit){
        return item.serialNumber.substr(3,8).indexOf(xABAB) >-1
      }else if(context.has4Limit){
        return item.serialNumber.substr(7,4).indexOf(xABAB) >-1
      }else{
        return item.serialNumber.indexOf(xABAB) >-1
      }
     }).length>0;
        
    })
}

getABABList = ()=>{
  if(this.state.ababList.length!==0){
    return this.state.ababList;
  }
  var ababList = [];
  for(var i=0;i<9;i++){
    for(var j=0;j<9;j++){
      ababList.push(i+''+j+''+i+''+j);
    }
  }
  this.setState({
    ababList:ababList

  });
  return ababList;
}


filterABAC = (nList,context)=>{
  if(!context.hasABAC){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  
  var checkABACList = this.getABACList();
  console.log("checkABACList begin");
  console.log(checkABACList)
  console.log("checkABACList end");
  return nList.filter((item)=>{ 
     return checkABACList.filter((xABAC)=>{
      if(context.has8Limit){
        return item.serialNumber.substr(3,8).indexOf(xABAC) >-1
      }else if(context.has4Limit){
        return item.serialNumber.substr(7,4).indexOf(xABAC) >-1
      }else{
        return item.serialNumber.indexOf(xABAC) >-1
      }
     }).length>0;
        
    })
}

getABACList = ()=>{
  if(this.state.abacList.length!==0){
    return this.state.abacList;
  }
  var abacList = [];
  for(var i=0;i<9;i++){
    for(var j=0;j<9;j++){
      for(var m=0;m<9;m++){
        abacList.push(i+''+j+''+i+''+m);
      }
    }
  }
  this.setState({
    abacList:abacList

  });
  return abacList;
}


filterABC = (nList,context)=>{
  if(!context.hasABC){
    return nList;
  }
  if(nList.length===0){
    return nList;
  }
  
  var checkABCList = this.getABCList();
  console.log("checkABCList begin");
  console.log(checkABCList)
  console.log("checkABCList end");
  return nList.filter((item)=>{ 
     return checkABCList.filter((xABC)=>{
      if(context.has8Limit){
        return item.serialNumber.substr(3,8).indexOf(xABC) >-1
      }else if(context.has4Limit){
        return item.serialNumber.substr(7,4).indexOf(xABC) >-1
      }else{
        return item.serialNumber.indexOf(xABC) >-1
      }
     }).length>0;
        
    })
}

getABCList = ()=>{
  if(this.state.abcList.length!==0){
    return this.state.abcList;
  }
  var abcList = [];
  for(var i=0;i<7;i++){
    abcList.push(i+''+(i+1)+''+(i+2));
  }
  for(var i=9;i>3;i--){
    abcList.push(i+''+(i-1)+''+(i-2));
  }
  this.setState({
    abcList:abcList

  });
  return abcList;
}

chooseUrl = (number,city,goodsId,that)=>{
/*   if(number.length>4){
      console.log("超过4位，无需传数字")
      return "v2/"+that.state.channelCode+"/phoneNum/select?belongCity="+city+'&goodsId='+goodsId;
  }
  console.log("4位以内，需要摆动")
  console.log("counter:"+ that.state.counter % 2);
  that.setState( {counter:that.state.counter+1 })
  if(that.state.counter % 2 === 1){
    return "v2/"+that.chooseChannelCode() + "/phoneNum/select?belongCity="+city+"&keyNum="+number.value+'&goodsId='+goodsId;
  }
  return "v2/"+ that.chooseChannelCode()+"/phoneNum/select?belongCity="+city+'&goodsId='+goodsId; */

  let {postCityCode,postProvinceCode} = that.state;
  let searchValue = number.value;

  return `hopeapi/lt/cha?cityCode=${postCityCode}&goodsId=${goodsId}&provinceCode=${postProvinceCode}&searchValue=${searchValue}&searchType=01`
}

chooseChannelCode = ()=>{

      if("菜鸟宝卡mini,菜鸟大宝,菜鸟小宝,联通语音王48,联通语音王68,联通语音王88,小龙卡,天龙卡,大龙卡,飞浪卡".indexOf(this.state.productName)>-1){

        if("深圳".indexOf(this.state.cityName)>-1){
          return "shenzhen";
         }
          
         if("佛山".indexOf(this.state.cityName)>-1){

          return "foshan";
        }

        return "shantou";
      }

      if(this.state.productName.indexOf("佛山联通王卡")>-1){

        return "foshan";
      }


      if("阿里钉钉商务银卡".indexOf(this.state.productName)>-1){

        return "shantou";
      }
      
      if("大乐卡".indexOf(this.state.productName)>-1){

        return "shantou";
      }


      if("广东流量王超享版".indexOf(this.state.productName)>-1){
        return "shantou";
      }

      if("联通王卡".indexOf(this.state.productName)>-1){
        return "shenzhen";
      }

      if("深圳流量王超享版".indexOf(this.state.productName)>-1){
        return "shenzhen";
      }

      if("佛山".indexOf(this.state.cityName)>-1){

        return "foshan";
      }

      if("深圳".indexOf(this.state.cityName)>-1){
        return "shenzhen";
       }
      
       if("百城奋斗卡-已下线".indexOf(this.state.productName)>-1){
        return "shantou";
      }

      if("国民奋斗卡-已下线".indexOf(this.state.productName)>-1){
        return "shantou";
      }
     

      if("全民奋斗卡-已下线".indexOf(this.state.productName)>-1){
        return "shantou";
      }
     

     if("北京".indexOf(this.state.cityName)>-1){
       return "beijing";
     }
     
     if("福州,厦门,莆田,三明,泉州,漳州,南平,龙岩,宁德".indexOf(this.state.cityName)>-1){
       return "fujian";
     }

     if("广州,深圳,珠海,汕头,佛山,韶关,湛江,肇庆,江门,茂名,惠州,梅州,汕尾,河源,阳江,清远,东莞,中山,潮州,揭阳,云浮".indexOf(this.state.cityName)>-1){
      return "shantou";
     }
     //北京与福建摇摆 50%分流
     //需要确保单次扫号期间不能随意变化
     if(this.state.cacheChannelCode){
      return this.state.cacheChannelCode;
     }
     let rndChannelCode = ["fujian","beijing"][Math.round(Math.random())];
     this.setState({cacheChannelCode: rndChannelCode});
     return rndChannelCode;

}

onStopClick = (event)=>{
  event.preventDefault() //阻止表单提交
  this.setState({
    scanRun:false
  });
  if(this.job!=null){
    clearInterval(this.job);
  }

}
handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number} = this
  var that = this
  console.log("hasNo4 value:"+ this.state.hasNo4);
  console.log("has3A value:"+ this.state.has3A);

 
  if("深圳流量王超享版".indexOf(this.state.productName)>-1){
    if("深圳".indexOf(this.state.cityName)===-1){
      alert("深圳流量王仅限深圳归属地");
      return;
     }
  }

 

  let orderdata = new FormData();
  orderdata.append('msg',"产品："+this.state.productName+";城市:"+this.state.cityName);
  axios.post("/lkjapi/debug.php",orderdata)
  .then(function (response) {
    console.log(response);
 
    
  });

  that.setState({
    scanRun:true
  });
  if(this.job!=null){
    clearInterval(this.job);
    that.setState({
      clickCount: 0,numbers:[],totalCount:0,
      findCount:0,counter:0,
      showCity:false
    });
  }
  this.job = setInterval(() => {
    axios.post( that.chooseUrl(number,that.state.cityCode,that.state.goodsId,that) )
    .then(function (response) {
        console.log("request done");
        console.log(response);
        
        if(response.data.status ){
          console.log(response.data.data);
          that.setState({
            totalCount: that.state.totalCount+response.data.data.length
          })
          var  finalNumbers = that.filterKeyNumber(response.data.data,number,that.state);
          console.log("finalNumbers===============Begin:");
          console.log(finalNumbers);
          console.log("finalNumbers===============End:");
  
          finalNumbers = that.filterNo4(finalNumbers, that.state.hasNo4);
          console.log("hasNo4===============Begin:");
          console.log(finalNumbers);
          console.log("hasNo4===============End:");
  
          console.log("无4过滤器之后 数量："+finalNumbers.length);
          finalNumbers = that.filter3A(finalNumbers, that.state);

          console.log("has3A===============Begin:");
          console.log(finalNumbers);
          console.log("has3A===============End:");
          console.log("has3A滤器之后 数量："+finalNumbers.length);

          finalNumbers = that.filter4A(finalNumbers, that.state);

          console.log("has4A===============Begin:");
          console.log(finalNumbers);
          console.log("has4A===============End:");
          console.log("has4A滤器之后 数量："+finalNumbers.length);

          finalNumbers = that.filterAABB(finalNumbers, that.state);

          console.log("hasAABB===============Begin:");
          console.log(finalNumbers);
          console.log("hasAABB===============End:");
          console.log("AABB滤器之后 数量："+finalNumbers.length);

          finalNumbers = that.filterABAB(finalNumbers,  that.state);

          console.log("hasABAB===============Begin:");
          console.log(finalNumbers);
          console.log("hasABAB===============End:");
          console.log("ABAB滤器之后 数量："+finalNumbers.length);

          finalNumbers = that.filterABAC(finalNumbers, that.state);

          console.log("filterABAC===============Begin:");
          console.log(finalNumbers);
          console.log("filterABAC===============End:");
          console.log("ABAC滤器之后 数量："+finalNumbers.length);

          finalNumbers = that.filterABC(finalNumbers, that.state);

          console.log("filterABC===============Begin:");
          console.log(finalNumbers);
          console.log("filterABC===============End:");
          console.log("ABC滤器之后 数量："+finalNumbers.length);

          
  
          //找到新号码中不在老号码中的
          if(finalNumbers.length>0){
            var  newNumbers = finalNumbers.filter(item => !that.state.numbers.find(x=>x.serialNumber===item.serialNumber));
            console.log("newNumbers===============Begin:");
            console.log(newNumbers);
            console.log("newNumbers===============End:");
            that.setState({
              numbers:[...newNumbers,...that.state.numbers]
            })
            that.setState({
              findCount: that.state.numbers.length
            })
          } 
        }
    
    })
  }, 1000);
  
}

onChangeHasNo4 = (event)=>{
  this.setState({
    hasNo4:!this.state.hasNo4
  });
  //console.log("event.target.value:"+event.target.value)
  //console.log("hasNo4 value:"+ this.state.hasNo4);
}

onChangeHas3A = (event)=>{
  this.setState({
    has3A:!this.state.has3A
  });
  //console.log("event.target.value:"+event.target.value)
  //console.log("has3A value:"+ this.state.has3A);
}

onChangeHas4A = (event)=>{
  this.setState({
    has4A:!this.state.has4A
  });
  //console.log("event.target.value:"+event.target.value)
  //console.log("has3A value:"+ this.state.has3A);
}
onChangeHasAABB = (event)=>{
  this.setState({
    hasAABB:!this.state.hasAABB
  });
}
onChangeHasABAB = (event)=>{
  this.setState({
    hasABAB:!this.state.hasABAB
  });
}
onChangeHasABAC = (event)=>{
  this.setState({
    hasABAC:!this.state.hasABAC
  });
}
onChangeHasABC = (event)=>{
  this.setState({
    hasABC:!this.state.hasABC
  });
}
onChangeHas8Limit = (event)=>{
  this.setState({
    has8Limit:!this.state.has8Limit
  });
}
onChangeHas4Limit = (event)=>{
  this.setState({
    has4Limit:!this.state.has4Limit
  });
}
onCityClick = (cityCode,cityName)=>{
  //console.log("app cityCode:"+cityCode)
  //console.log("app cityName:"+ cityName);
  //event.preventDefault() //阻止表单提交
  
  //找到联通所需要的provinceCode,cityCode

  console.log(ProvinceCityData);

  var  ltProCity = ProvinceCityData.find(x => x.numCityCode===cityCode);

  console.log(ltProCity);

  this.setState({
    showCity:!this.state.showCity,
    cityCode:cityCode,
    cityName:cityName,
    postCityCode:ltProCity.postCityCode,
    postProvinceCode:ltProCity.postProvinceCode
  });
}

onProductClick = (product)=>{
  //event.preventDefault() //阻止表单提交

  let {goodsId,productName,productVCode,fixCityName,fixCityCode} = product;

  console.log("客户选择"+goodsId+","+productName);
  console.log("fixCityCode:"+fixCityCode);
  console.log(fixCityCode);
  console.log(JSON.stringify(product));

  //如果固定城市配置存在，则固定归属地
  if(fixCityCode!==undefined &&fixCityCode!==null && fixCityCode!==""){
    var  ltProCity = ProvinceCityData.find(x => x.numCityCode===fixCityCode);
    console.log(ltProCity);
    this.setState({
      showProduct:!this.state.showProduct,
      goodsId:goodsId,
      productName:productName,
      showCityText:false,
      productVCode:productVCode,
      cityName: fixCityName,
      cityCode: fixCityCode,
      postCityCode:ltProCity.postCityCode,
      postProvinceCode:ltProCity.postProvinceCode
    })
    return;
  }

  //
  if(goodsId==="833203012862" || goodsId==="832112239412"
   || goodsId==="833203012940" || goodsId==="833203013421"
   || goodsId==="833203013427" || goodsId==="833203013430"){

    console.log("默认固定重庆归属地")
    this.setState({
      showProduct:!this.state.showProduct,
      goodsId:goodsId,
      productName:productName,
      cityName: "重庆",
      cityCode:  831,
      showCityText:false,
      productVCode:productVCode
    })

    return;
  }

  this.setState({
    showProduct:!this.state.showProduct,
    goodsId:goodsId,
    productName:productName,
    productVCode:productVCode,
    showCityText:true
  });
}

onChannelClick = (channelCode,channelName)=>{
  //console.log("app cityCode:"+cityCode)
  //console.log("app cityName:"+ cityName);
  //event.preventDefault() //阻止表单提交
  console.log("channelCode:"+channelCode);
  console.log("channelName:"+channelName);
  this.setState({
    showChannel:!this.state.showChannel,
    channelCode: channelCode,
    channelName: channelName
  });
}

onChooseCityClick = (event)=>{
  event.preventDefault() //阻止表单提交
 
  this.setState({
    showCity:!this.state.showCity
  });
  console.log("this.state.showCity:"+this.state.showCity);
}

onChooseProductClick = (event)=>{
  event.preventDefault() //阻止表单提交
 
  this.setState({
    showProduct:!this.state.showProduct
  });
  console.log("this.state.showProduct:"+this.state.showProduct);

}

onChooseChannelClick = (event)=>{
  event.preventDefault() //阻止表单提交
 
  this.setState({
    showChannel:!this.state.showChannel
  });
  console.log("this.state.showChannel:"+this.state.showChannel);

}

  render() {
 
   return  <div className="App">
          <Container>
          <Breadcrumb>
          <Breadcrumb.Item >通信店</Breadcrumb.Item>
          <Breadcrumb.Item href={"#/query?u="+this.state.u}>
              查单
            </Breadcrumb.Item>
            <Breadcrumb.Item href={"#/big?u="+this.state.u}>
              特色卡
            </Breadcrumb.Item>
            <Breadcrumb.Item href={"#/trade?u="+this.state.u+"&rnd="+Math.random()}>
              无限卡
            </Breadcrumb.Item>

            <Breadcrumb.Item href={"#/cheing?u="+this.state.u}>
              好车
            </Breadcrumb.Item>

            <Breadcrumb.Item active>扫号</Breadcrumb.Item>
          </Breadcrumb>


         
          <ul className="nav">
              <li className="nav-item"  >
              <button  type="button" className="btn btn-outline-primary btn-sm" onClick={this.onChooseProductClick} >{this.state.productName}|切</button> 
              </li>

              <li className="nav-item"  style={{marginLeft: '0.3em', display: this.state.showCityText?'block':'none'}}>
                  <button  type="button" className="btn btn-outline-primary btn-sm" onClick={this.onChooseCityClick}>{this.state.cityName}|切</button> 
              </li>

          </ul>
 

          <Modal show={this.state.showCity}  size="lg">
          <Modal.Body>
          <CityLetterList onClick={this.onCityClick}     />
          </Modal.Body>
          </Modal>

          <Modal show={this.state.showProduct}  size="lg">
            <Modal.Body>
            <Container>
              <ProductList onClick={this.onProductClick}  />
            </Container>
            </Modal.Body>
          </Modal>

          <Modal show={this.state.showChannel}  size="lg">
            <Modal.Body>
            <Container>
              <ChannelList onClick={this.onChannelClick}  />
            </Container>
            </Modal.Body>
          </Modal>

          <Form style={{marginTop: '1em'}}>
          <Form.Group controlId="formBasicNumber">
            <Form.Control type="text" placeholder="输入心仪的数字" ref={c => this.number = c}  name="number" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>
       
          <Form.Group controlId="formBasicCheckboxHasNo4" className="d-flex p-2 bd-highlight">
            <Form.Check type="checkbox"  label="无4" checked={this.state.hasNo4} style={{marginRight: '1em'}} onChange={this.onChangeHasNo4} />
            <Form.Check type="checkbox"  label="3A" checked={this.state.has3A}  style={{marginRight: '1em'}}    onChange={this.onChangeHas3A} />
            <Form.Check type="checkbox"  label="4A" checked={this.state.has4A}  style={{marginRight: '1em'}}     onChange={this.onChangeHas4A} />
            <Form.Check type="checkbox"  label="ABC" checked={this.state.hasABC}      onChange={this.onChangeHasABC} />
          </Form.Group>

          <Form.Group controlId="formBasicCheckboxHasNo5" className="d-flex p-2 bd-highlight">
            <Form.Check type="checkbox"  label="AABB" checked={this.state.hasAABB}  style={{marginRight: '1em'}}     onChange={this.onChangeHasAABB} />
            <Form.Check type="checkbox"  label="ABAB" checked={this.state.hasABAB} style={{marginRight: '1em'}}     onChange={this.onChangeHasABAB} />
            <Form.Check type="checkbox"  label="ABAC" checked={this.state.hasABAC}     onChange={this.onChangeHasABAC} />
          </Form.Group>

          <Form.Group controlId="formBasicCheckboxHasNo6" className="d-flex p-2 bd-highlight">
            <Form.Check type="checkbox"  label="扫后8位" checked={this.state.has8Limit}  style={{marginRight: '1em'}}     onChange={this.onChangeHas8Limit} />
            <Form.Check type="checkbox"  label="扫后4位" checked={this.state.has4Limit} style={{marginRight: '1em'}}     onChange={this.onChangeHas4Limit} />
 
          </Form.Group>

         <div className="text-center">
          <Button variant="primary"  style={{ display: this.state.scanRun?"none":"block" }}  type="button"  onClick={this.handleSubmit}  className="btn btn-primary btn-lg btn-block">
            开始选号
          </Button>

          <Button variant="secondary" style={{ display: this.state.scanRun?"block":"none" }}   type="button"  onClick={this.onStopClick}  className="btn btn-primary btn-lg btn-block">
            停止
          </Button>
          </div>

        </Form>
       
        <div className="d-flex flex-row bd-highlight mb-3">
          <div className="d-inline p-2 bg-primary text-white mt-3">扫描总数：{this.state.totalCount} </div>
          <div className="d-inline p-2 bg-dark text-white mt-3">找到个数：{this.state.findCount} </div>
          <Spinner animation="border" className="mt-3 ml-3" role="status" style={{ display: this.state.scanRun?"block":"none" }}>
          <span className="visually-hidden">号</span>
        </Spinner>
        </div>
        <NumberList numbers={this.state.numbers} channelCode={this.chooseChannelCode()} 
         u={this.state.u} 
         productVCode={this.state.productVCode}

         postCityCode={this.state.postCityCode}

         postProvinceCode={this.state.postProvinceCode}
           
         />

        <FlexProductPanel u={this.state.u}></FlexProductPanel>
   
        <Card body className="mt-3">办理阿里钉钉商务银卡 推荐选汕头、深圳、宁德、唐山、广州、银川、广西、湖北襄阳、北京、苏州，昆山，南京、四川、江西归属地，其他地区可自测</Card>
        <Card body className="mt-3">电霸卡39元585G 发车中</Card> 
        <Card body className="mt-3">嗨钉卡33元20G通用+600分钟+钉钉定向双不限 发车中</Card>
        <Card body className="mt-3">联通智慧沃家38元双不限发车中，带500分钟通话！详情咨询客服</Card>
        <Card body className="mt-3">大鱼卡 5G双不限卡 199元/月 发车中</Card>
        <Card body className="mt-3">开联通工号，为联通打Call！详询客服</Card>
        <Card body className="mt-3">错过了阿里宝宝卡、百度云圣卡、导学易学卡？备联通卡保号，候车！</Card>
        <Card body className="mt-3">默认套餐为大王卡，扫到喜欢的号码及时下单，避免被他人抢先。如需全国范围自动扫，使用高级版本 。</Card>
          
        <Card body className="mt-3">移动联通电信卡号套餐合作</Card>
        <Card body className="mt-3">平台客服</Card>
        <Card body className="mt-3">&copy; 2015-2029 撸卡君 京ICP备15052719号-1</Card>

        </Container>
       
    </div>
  }
}

export default HomeScreen;
